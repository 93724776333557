import React, {useRef, useState, useEffect} from 'react';
import {useAuth} from '../auth/AuthContext';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useCallback } from 'react';
import { getAuth } from "firebase/auth";
import Vocab from './Vocab';
import { IoHome, IoVolumeMute,IoVolumeHigh, IoLogOut, IoSettings } from "react-icons/io5";
import { MdFiberNew } from "react-icons/md";
import Joke from './Joke';
import Fact from './Fact';
import Meme from './Meme';
import WordPair from './WordPair';
import CorrectWord from './CorrectWord';
import { ReactComponent as LanguageIcon } from '../svg/languageLoL.svg';
import { Link } from "react-router-dom";
import { GiRank3 } from "react-icons/gi";
import XPCounter from './XPCounter';
import StreakCounter from './StreakCounter';
import RankCard from './RankCard';
import Quote from './Quote';
import SelectAudio from './SelectAudio';
import { onSnapshot,doc } from 'firebase/firestore';
import { db } from '../firebase';

import { SettingsDialog } from './SettingsDialog';


import {ReactComponent as Private} from '../svg/ranks/private.svg';
import {ReactComponent as Corporal} from '../svg/ranks/corporal.svg';
import {ReactComponent as PrivateFirstClass} from '../svg/ranks/private_first_class.svg';
import {ReactComponent as Sergeant} from '../svg/ranks/sergeant.svg';
import {ReactComponent as StaffSergeant} from '../svg/ranks/staff_sergeant.svg';
import {ReactComponent as MasterSergeant} from '../svg/ranks/master_sergeant.svg';
import {ReactComponent as Lieutenant} from '../svg/ranks/lieutneant.svg';
import {ReactComponent as Captain} from '../svg/ranks/captain.svg';
import {ReactComponent as Major} from '../svg/ranks/major.svg';
import {ReactComponent as LieutenantColonel} from '../svg/ranks/lieutneant-colonel.svg';
import {ReactComponent as Colonel} from '../svg/ranks/colonel.svg';
import {ReactComponent as Brigadier} from '../svg/ranks/brigardier.svg';
import {ReactComponent as MajorGeneral} from '../svg/ranks/major_general.svg';
import {ReactComponent as LieutenantGeneral} from '../svg/ranks/lieutneant_general.svg';
import {ReactComponent as General} from '../svg/ranks/general.svg';

import { ReactComponent as RussianFlag } from "../svg/flag_ru.svg";
import { ReactComponent as SpanishFlag } from "../svg/flag_es.svg";


let feedBack = [];

const FEED_HOME = "home";
const FEED_NEW = "new";

const TRAINING_TYPE_ARR = ["wordPair","correctWord"];

const RANKS = [
    "Private",
    "Private First Class",
    "Corporal",
    "Sergeant",
    "Staff Sergeant",
    "Master Sergeant",
    "Lieutenant",
    "Captain",
    "Major",
    "Lieutenant Colonel",
    "Colonel",
    "Brigadier",
    "Major General",
    "Lieutenant General",
    "General",
    "Continent Commander",
    "World Conquer",
    "Banana4scale Master",
    "Universal Meme'ster",
    "Multidimensional Meme God"
];

const RANKS_DAYS = [
    0, // Private
    1, // Private First Class
    2, // Corporal
    3, // Sergeant
    5, // Staff Sergeant
    7, // Master Sergeant
    10, // Lieutenant
    14, // Captain
    18, // Major
    24, // Lieutenant-Colonel
    30, // Colonel
    36, // Brigadier
    42, // Major-General
    50, // Lieutenant-General
    60, // General
    90, // Continent Commander
    140, // World Conquer
    200, // Banana4scale Master
    365, // Universal Meme'ster
    1000, // Multidimensional Meme God
];

export default function Feed(){

    const [content, setContent] = useState([]);
    const [xp, setXP] = useState(0);
    const [rank, setRank] = useState(RANKS[0]);
    const [streak, setStreak] = useState(0);
    const {currentUser, processedAuth, logout, currentUserState} = useAuth();
    const [success_audio] = useState(new Audio("https://firebasestorage.googleapis.com/v0/b/lang-fix-59804.appspot.com/o/util%2Fsuccess_sound_3.mp3?alt=media&token=fffb8288-9a3d-4a0d-a15e-85d19ab12c9b"));

    const [isOpen, setIsOpen] = useState(false);

    const functions = getFunctions();
    const getFeed = httpsCallable(functions, 'getFeed');
    const getNew = httpsCallable(functions, 'getNew');
    const updateUser = httpsCallable(functions, 'updateUser');

    const [lang, setLang] = useState(localStorage.getItem("LANGUAGE_STORAGE_ID")?localStorage.getItem("LANGUAGE_STORAGE_ID"):"es-ES");
    const [autoplay, setAutoplay] = useState(localStorage.getItem("AUTOPLAY_STORAGE_ID")?localStorage.getItem("AUTOPLAY_STORAGE_ID")==="true":false);
    const [selected, setSelected] = useState(FEED_HOME);

    const [currentDisplay, setCurrentDisplay] = useState(0);

    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex != 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    const loadFeed = async (blend=true,add=[]) => {
        console.log("loading feed");
        let feed = [];
        if(currentUser){
            
            feed = await getFeed({uid:currentUser.uid, feedback:feedBack, lang:lang, target:lang});
           
        }else{
            feed = await getFeed({feedback:feedBack, lang:lang, target:lang});
            let duplicate = JSON.parse(JSON.stringify(feed.data[0]));
            duplicate.t = TRAINING_TYPE_ARR[Math.floor(Math.random()*TRAINING_TYPE_ARR.length)];
            feed.data.push(duplicate);
            let duplicate1 = JSON.parse(JSON.stringify(feed.data[1]));
            duplicate1.t = TRAINING_TYPE_ARR[Math.floor(Math.random()*TRAINING_TYPE_ARR.length)];
            feed.data.push(duplicate1);
            let duplicate2 = JSON.parse(JSON.stringify(feed.data[2]));
            duplicate2.t = TRAINING_TYPE_ARR[Math.floor(Math.random()*TRAINING_TYPE_ARR.length)];
            feed.data.push(duplicate2);
            let duplicate3 = JSON.parse(JSON.stringify(feed.data[3]));
            duplicate3.t = TRAINING_TYPE_ARR[Math.floor(Math.random()*TRAINING_TYPE_ARR.length)];
            feed.data.push(duplicate3);
        }   

        if(feed.data.length<5){
            console.log("append feed");
            let duplicate = JSON.parse(JSON.stringify(feed.data[0]));
            duplicate.t = TRAINING_TYPE_ARR[Math.floor(Math.random()*TRAINING_TYPE_ARR.length)];
            feed.data.push(duplicate);
        }
        
        feedBack = [];
        shuffle(feed.data);
        console.log("got feed", feed.data);

        if(blend){
            setContent([...add, ...content, ...feed.data]);
        }else{
            setContent([...feed.data]);
        }
        
    }

    const loadNew = async () => {
        let feed;
        if(currentUser){
            feed = await getNew({uid:currentUser.uid, lang:lang, target:lang});
        }else{
            feed = await getNew({lang:lang, target:lang});
        }
        
        feedBack = [];
        setContent([...feed.data]);
    }

    

    useEffect(() => {
        setContent([]);
        let unSub;
        if(processedAuth){
            if(selected===FEED_HOME){
                loadFeed(true);
            }
            if(currentUserState){
                setXP(currentUserState.xp);
                setStreak(currentUserState.streak);
                setRank(getStreakRank(currentUserState.streak));
                console.log(currentUser.uid)
                unSub = onSnapshot(doc(db,"users",currentUser.uid),async (snap)=>{
                    if(snap.data()){
                        let data = snap.data()
                      console.log();
                        setStreak(data.streak);
                        setXP(data.xp);
                    }
                  });
            }
        }
        
        return ()=>{
            if(unSub)
                unSub();
            setContent([]);
        }
    }, [processedAuth]);

    useEffect(() => {
        setContent([]);
        if(processedAuth){
            loadFeed(false);
        }
        
        return ()=>{
            setContent([]);
        }
    }, [lang]);

    function getStreakRank(streak){
        let r = "";
        let counter = 0;
        while(!r){
            if(streak >= RANKS_DAYS[counter] && streak < RANKS_DAYS[counter+1]){
                r = RANKS[counter];
            }
            if(counter + 1 > RANKS_DAYS.length-1){
                return RANKS[RANKS.length-1];
            }else{
                counter++;
            }
        }
        return r;
    }

    function handleRemove(i){
        let c = [...content];
        c.splice(i,1);
        setContent(c);
    }

    function handleKnown(item,i){
        setXP(xp+1);
        success_audio.play()
        if(currentUser){
            if(item.state){
                item.state += 1;
            }else{
                item.state = 5;
            } 
    
            updateUser({uid:currentUser.uid, feedback:[item],xp:true, lang:lang});
        }

        let display = currentDisplay + 1;
        setCurrentDisplay(display);
        if(display === content.length-4){
            loadFeed();
        }
    }

    function setNext(){
        setXP(xp+1);
        let display = currentDisplay + 1;
        setCurrentDisplay(display);
        if(display === content.length-4){
            loadFeed();
        }
    }

    function handleRepeat(item,i){
        if(currentUser){
            if(!item.state){
                item.state = 1;
            }
            updateUser({uid:currentUser.uid, feedback:[item], lang:lang});
        }

        let display = currentDisplay + 1;
        setCurrentDisplay(display);
        if(display === content.length-4){
            loadFeed();
        }
    }

    function getCard(item, i){
        if(item){
            if(item.t === "vocab"){
                return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}><Vocab item={item} i={i} known={()=>{handleKnown(item,i)}} autoplay={autoplay} repeat={()=>{handleRepeat(item,i)}}></Vocab></div>
            }else if(item.t === "joke"){
                return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}><Joke item={item} i={i} known={()=>{handleKnown(item,i)}} autoplay={autoplay} repeat={()=>{handleRepeat(item,i)}}></Joke></div>
            }else if(item.t === "quote"){
                return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}><Quote item={item} i={i} known={()=>{handleKnown(item,i)}} autoplay={autoplay} repeat={()=>{handleRepeat(item,i)}}></Quote></div>
            }else if(item.t === "fact"){
                return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}><Fact item={item} i={i} known={()=>{handleKnown(item,i)}} autoplay={autoplay} repeat={()=>{handleRepeat(item,i)}}></Fact></div>
            }else if(item.t === "rankCard"){
                return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}><RankCard next={()=>{setNext()}}></RankCard></div>;
            }else if(item.t === "meme"){
                return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}>
                    <Meme item={item} i={i} known={()=>{handleKnown(item,i)}} autoplay={autoplay} repeat={()=>{handleRepeat(item,i)}}></Meme>
                </div>
            }else if(item.t === "wordPair"){
                return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}>
                    <WordPair item={item} i={i} known={()=>{handleKnown(item,i)}} lang={lang}></WordPair>
                </div>
            }else if(item.t === "selectAudio"){
                return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}>
                    <SelectAudio item={item} i={i} known={()=>{handleKnown(item,i)}} autoplay={autoplay} repeat={()=>{handleRepeat(item,i)}}></SelectAudio>
                </div>
            }else{
                return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}>
                <CorrectWord item={item} i={i} known={()=>{handleKnown(item,i)}} lang={lang}></CorrectWord>
                </div>
            }
        }else{
            return <div className="-rotate-3 shadow-xl" key={"last-"+i}>
                <div>
                    <div className="flex flex-col bg-green-600 rounded">
                        <div className="text-xl font-bold mt-2 text-white p-2 text-white">
                            very good 
                            <span className="inline text-green-200 font-bold glow-shadow-green inline-flex flex-row items-center mx-2">{rank}</span>
                            you repeated everything on your stack!
                        </div>
                        <div className="bg-green-700 rounded-b flex flex-row font-bold mt-2 text-white p-2 text-white">
                        <div onClick={()=>{setSelected(FEED_HOME);setContent([]);loadFeed(false);}} 
                            className={`cursor-pointer  m-2 text-white rounded-full`}>
                            Load more
                        </div>
                        </div>
                    </div>
                </div>
                    
            </div>
        }
    }

    function getCardColor(item){
        if(item){
            if(item.t === "vocab"){
                return "blue";
            }else if(item.t === "joke"){
                return "green";
            }else if(item.t === "fact"){
                return "sky";
            }else if(item.t === "meme"){
                return "purple";
            }else if(item.t === "quote"){
                return "orange";
            }else if(item.t === "wordPair"){
                return "rose";
            }else if(item.t === "rankCard"){
                return "emerald";
            }else if(item.t === "selectAudio"){
                return "emerald";
            }else{
                return "purple"
            }
        }else{
            return "green";
        }
    }

    function getRankSymbol(rank){
        if(rank === "Private"){
            return <Private className='glow-shadow-green-drop mr-1 h-6'></Private>
        }else if(rank === "Private First Class"){
            return <PrivateFirstClass className='glow-shadow-green-drop mr-1 h-6'></PrivateFirstClass>
        }else if(rank === "Corporal"){
            return <Corporal className='glow-shadow-green-drop mr-1 h-6'></Corporal>
        }else if(rank === "Sergeant"){
            return <Sergeant className='glow-shadow-green-drop mr-1 h-6'></Sergeant>
        }else if(rank === "Staff Sergeant"){
            return <StaffSergeant className='glow-shadow-green-drop mr-1 h-6'></StaffSergeant>
        }else if(rank === "Master Sergeant"){
            return <MasterSergeant className='glow-shadow-green-drop mr-1 h-6'></MasterSergeant>
        }else if(rank === "Lieutenant"){
            return <Lieutenant className='glow-shadow-green-drop mr-1 h-6'></Lieutenant>
        }else if(rank === "Captain"){
            return <Captain className='glow-shadow-green-drop mr-1 h-6'></Captain>
        }else if(rank === "Major"){
            return <Major className='glow-shadow-green-drop mr-1 h-6'></Major>
        }else if(rank === "Lieutenant Colonel"){
            return <LieutenantColonel className='glow-shadow-green-drop mr-1 h-6'></LieutenantColonel>
        }else if(rank === "Colonel"){
            return <Colonel className='glow-shadow-green-drop mr-1 h-6'></Colonel>
        }else if(rank === "Brigadier"){
            return <Brigadier className='glow-shadow-green-drop mr-1 h-6'></Brigadier>
        }else if(rank === "Major General"){
            return <MajorGeneral className='glow-shadow-green-drop mr-1 h-6'></MajorGeneral>
        }else if(rank === "Lieutenant General"){
            return <LieutenantGeneral className='glow-shadow-green-drop mr-1 h-6'></LieutenantGeneral>
        }else{
            return <General className='glow-shadow-green-drop mr-1 h-6'></General>
        }
    }

    function getFlag(){
        if(lang === "ru-RU"){
            return <RussianFlag className='h-4 w-4 rounded-full top-1 absolute left-1 border-2 border-rose-700'></RussianFlag>
        }else if(lang === "es-ES"){
            return <SpanishFlag className='h-4 w-4 rounded-full top-1 absolute left-1 border-2 border-rose-700'></SpanishFlag>
        }else{
            return <SpanishFlag className='h-4 w-4 rounded-full top-1 absolute left-1 border-2 border-rose-700'></SpanishFlag>
        }
    }

    return <div className="flex flex-col-reverse sm:flex-row justify-between sm:justify-center w-full bg-slate-900 w-screen h-screen">
        <div className="my-4 sm:my-0 ml-4 flex flex-row sm:h-screen mb-16 justify-center sm:flex-col items-center sm:items-end">
                {!currentUser?<>
                    <Link to={"/signin"}>
                    <div className="flex flex-row w-full m-1 justify-center">
                    <div className="flex justify-center rounded text-gray-400 font-bold pointer-cursor mr-2 sm:mb-2">
                        Sign in
                    </div>
                    </div>
                </Link>
                <Link to={"/signup"}>
                    <div className="flex flex-row w-full m-1 justify-center">
                    <div className="flex justify-center rounded bg-rose-700 text-white rounded font-bold pointer-cursor p-2 sm:mb-8 sm:mr-0 mr-4">
                        Sign up
                    </div>
                    </div>
                </Link>
                </>:""}
            <IoHome onClick={()=>{setSelected(FEED_HOME);setContent([]);loadFeed(false);}} className={`cursor-pointer w-8 h-8 m-2 ${selected===FEED_HOME&&!isOpen?"bg-rose-700 m-0 text-white h-12 w-12 p-2 sm:mb-4":"text-slate-400 sm:mb-6"} rounded-full`}></IoHome>
            {false?<MdFiberNew onClick={()=>{setSelected(FEED_NEW);setContent([]);loadNew();}} className={`cursor-pointer w-8 h-8 m-2 ${selected===FEED_NEW&&!isOpen?"bg-rose-700 m-0 text-white h-12 w-12 p-2":"text-slate-400"} rounded-full`}></MdFiberNew>:""}
            {autoplay?<IoVolumeHigh onClick={() => {
                    setAutoplay(false);
                    localStorage.setItem("AUTOPLAY_STORAGE_ID", false);
                }} className={`cursor-pointer w-8 h-8 m-2 sm:mb-4 ml-4 sm:ml-0 text-slate-400 rounded-full`}></IoVolumeHigh>:
                <IoVolumeMute onClick={() => {
                    setAutoplay(true);
                    localStorage.setItem("AUTOPLAY_STORAGE_ID", true);
                }} className={`cursor-pointer w-8 h-8 m-2 text-slate-400 ml-4 sm:ml-0 sm:mb-4 rounded-full`}></IoVolumeMute>}
            <div className="relative">
                {getFlag()}
                <IoSettings onClick={()=>{setIsOpen(true)}} className={`cursor-pointer w-8 h-8 m-2 ${isOpen?"bg-rose-700 m-0 text-white h-12 w-12 p-2":"text-slate-400"} rounded-full`}></IoSettings>
            </div>
            
          {currentUser?<IoLogOut onClick={()=>{logout()}} className="cursor-pointer w-8 h-8 m-2 sm:mt-4 text-rose-700"></IoLogOut>:""}
        </div>
        <div className="flex flex-col flex-1 sm:flex-none items-center">
            <LanguageIcon className='hidden sm:block h-12 mt-8 sm:my-4 max-w-md'></LanguageIcon>
            <div className="mt-8 ml-4 flex flex-row justify-center items-center sm:items-start">
                <XPCounter xp={xp}></XPCounter>
                <StreakCounter day={streak}></StreakCounter>
                <div className="text-green-500 font-bold glow-shadow-green flex flex-row items-center mr-2 whitespace-nowrap"> {getRankSymbol(rank)} {rank}</div>
            </div>
            <div className="flex items-center flex-col w-screen sm:max-w-md flex-1  justify-center overflow-scroll">
                <div className="text-center p-4 w-screen sm:w-full overflow-hidden sm:-mt-10">
                    {content.length>0?<div className={`rotate-3 w-full h-full bg-${getCardColor(content[currentDisplay+1])}-800 rounded`}>{getCard(content[currentDisplay], currentDisplay)}</div>:<div className="flex flex-row justify-center"><span className="loader"></span></div>}
                </div>
                <div className="text-slate-400 text-sm">This website is free and has no ads</div>
                    <a className="text-slate-400 text-bold text-sm" rel="noreferrer" target="_blank" href="https://www.buymeacoffee.com/joshuamschmidt">so maybe <span className="text-rose-500 font-bold"> buy me a coffee</span> :)</a>
            </div>
        </div>
        
       <SettingsDialog isOpen={isOpen} setIsOpen={(val)=>{setIsOpen(val)}} setLang={setLang}></SettingsDialog>
    </div>
}
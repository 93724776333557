import React, {useRef, useState, useEffect} from 'react';
import {IoEye, IoCheckmarkDone, IoEyeOff} from "react-icons/io5";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { getLink } from '../util/Util';
import AudioPlayer from './AudioPlayer';

export default function Quote({item, i, repeat, known, autoplay}){

    const [show,setShow] = useState(false);
    const [showed, setShowed] = useState(false);

    function textSizes(text){
        if(text.length > 60){
            return "1xl";
        }else{
            return "2xl";
        }
    }

    return <div>
        <div className="flex flex-col bg-orange-800 min-card-height justify-between rounded bg-cover background-orange" style={{backgroundImage: `url(${item.background_url})`}}>
            {show?
            <div className={`text-white px-8 pt-8 tracking-wide text-${textSizes(item.back)} leading-relaxed font-serif`}>{item.back}<span className='block mt-4 ml-4 text-orange-300 font-thin italic'>{item.back_author}</span></div>:
            <div className={`text-white px-8 pt-8 tracking-wide text-${textSizes(item.text)} leading-relaxed font-serif`}>{item.text}<span className='block mt-4 ml-4 text-orange-300 font-thin italic'>{item.text_author}</span></div>}

<div className="flex flex-row font-bold mt-2 text-white p-2 text-white">
            {show?
                <> <div className="text-center cursor-pointer" onClick={()=>{setShow(false)}}><IoEyeOff className="text-white w-10 p-2 h-10 m-2 bg-orange-900 rounded-full"></IoEyeOff></div>
                    <div className="text-center cursor-pointer" onClick={()=>{known(i)}}><IoCheckmarkDone className="text-white w-10 p-2 h-10 m-2 bg-orange-900 rounded-full"></IoCheckmarkDone></div></>
            :<><div className="text-center cursor-pointer" onClick={()=>{setShow(true); setShowed(true)}}><IoEye className="text-white w-10 p-2 h-10 m-2 bg-orange-900 rounded-full"></IoEye></div>
            <div className="text-center cursor-pointer" onClick={()=>{showed?repeat(i):known(i)}}><IoCheckmarkDone className="text-white w-10 p-2 h-10 m-2 bg-orange-900 rounded-full"></IoCheckmarkDone></div></>
            }
            {item.uuid?<div className=" w-10 p-2 h-10 m-2 bg-orange-900 rounded-full"><AudioPlayer inactive={400} color={"orange"} audio={getLink("ru-RU",item.hash,item.uuid)} size={6} play={autoplay}></AudioPlayer></div>:""}
            
            </div>
            
        </div>
        
    </div>
}
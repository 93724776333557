import React, {useRef, useState, useEffect} from 'react';
import {IoEye, IoCheckmarkDone,IoEyeOff, IoPlay} from "react-icons/io5";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export default function ViewerMeme({item, i, state}){

    function textSizes(text){
        if(text.length > 20){
            return "1xl";
        }else{
            return "2xl";
        }
    }

    /*
 

                <div contentEditable='true' className={`flex flex-col p-8 text-white text-${textSizes(item.origin)}`}>
               <div className="text-4xl text-xl text-center font-bold ">🥳 follow for more 🥳</div>
               <div className="text-4xl text-rose-500 text-center font-light my-4" >or go to</div>
                <div className="text-4xl text-center font-bold">🔗 languageLoL.com</div>
                <div className="text-base text-purple-200 text-center my-2">for hundreds of bilingual <strong className="text-rose-500">memes, <br></br>quotes, facts</strong> and <strong className="text-rose-500">exercises</strong></div>
               <div className=" text-purple-200 text-center text-xl font-light">(link in description)</div>
                </div>}
        
            </div>
    */

    return <div>
        <div className="bg-purple-800 rounded mb-4">
            <div className="">
            {item.translation_url&&state===0?<img src={item.translation_url}  className="rounded"></img>:
            state===1?<img src={item.background_url}  className="rounded"></img>:
            <div contentEditable='true' className={`flex flex-col p-8 text-white text-${textSizes(item.origin)}`}>
               <div className="font-serif font-bold">Words:</div>
                <div className={`flex flex-row`}>
                    <div className={`flex flex-col flex-1`}>
                    {item.target.slice(0,16).map((word,i)=>{
                        if(word.length > 3){
                            return <div>{word} - <span className="text-orange-200">{item.origin[i]}</span></div>
                        }else{
                            return "";
                        }
                    })}
                    </div>
                    <div className={`flex flex-col ${textSizes(item.origin)==="1xl"?"flex-1":""}`}>
                    {item.target.slice(16,item.target.length-1).map((word,i)=>{
                        if(word.length > 3){
                            return <div>{word} - <span className="text-orange-200">{item.origin[16+i]}</span></div>
                        }else{
                            return "";
                        }
                    })}
                    </div>
                </div>
                </div>}
        
            </div>
        </div>
    </div>
}
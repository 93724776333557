import React, {useRef, useState, useEffect} from 'react';
import {IoVolumeHigh, IoCheckmarkDone,IoEyeOff, IoGitMergeOutline} from "react-icons/io5";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import generateRandomArray, { getLink, shuffle, timeout } from '../util/Util';
import AudioPlayer from './AudioPlayer';

export default function SelectAudio({item, repeat, known}){ 

    console.log(item);
    
    const [correct, setCorrect] = useState(false);
    const [random, setRandom] = useState(0);
    const [choices, setChoices] = useState([]);
    const [lItem, setLitem] = useState(item);

    useEffect(()=>{
        let r = Math.floor(Math.random()*lItem.target.length);
        setRandom(r);
        
        if(lItem.target.length < 7){
            setChoices(shuffle(lItem.target));
        }else{
            let randArr = generateRandomArray(7,lItem.target.length,[r]);
            let temp = [];
            randArr.forEach((randId) => {
                temp.push([lItem.origin[randId],lItem.hashes[randId],lItem.uuids[randId]]);
            })
            shuffle(temp);
            setChoices(temp);
        }
    },[]);

    return <div>
        <div className="flex flex-col bg-emerald-800 rounded">
            <div className="text-emerald-900 bg-contain bg-no-repeat bg-center">
            <div className="bg-emerald-800 w-full h-full rounded">
                    <div className="text-xl bg-emerald-900 text-white p-4 rounded-t">Tab the word that means:
                        <div className="text-4xl my-4 underline text-emerald-400 w-full flex flex-row justify-center">
                        <AudioPlayer inactive={400} color={"emerald"} size={6} audio={getLink("ru-RU",lItem.hashes[random],lItem.uuids[random])} play={false}>
                            <IoVolumeHigh className="h-14 w-14 p-2 rounded-full bg-emerald-700 text-emerald-300"></IoVolumeHigh>
                        </AudioPlayer>
                        
                        </div>
                    </div>
                    
                    <div className="p-4 text-center">{choices.map((item,i) => {
                        return <div onClick={async ()=>{if(item[0]===lItem.origin[random]){setCorrect(true);await timeout(1000);known()}else{console.log("false",item[0],i,random,lItem.target[random])}}} className={`cursor-pointer inline-block p-2 my-2 mx-1 whitespace-nowrap ${correct&&item[0]===lItem.target[random]?"bg-green-600":"bg-emerald-700"} rounded-full text-white`}>{item[0]}</div>
                    })}</div>
                    
                </div>
               
            </div>
        </div>
    </div>
}
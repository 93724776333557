import React, {useRef, useState, useEffect} from 'react';
import {IoEye, IoCheckmarkDone} from "react-icons/io5";

export default function Vocab({item, i, repeat, known}){

    const [show,setShow] = useState(false);

    return <div>
        <div className="flex flex-col bg-sky-600 rounded m-2 ">
            {show?<><div className="text-white text-3xl p-4">{item.origin}</div>
            <div className="flex flex-row font-bold mt-2 text-slate border-t p-4 text-white">
                <div className="flex-1 text-center" onClick={()=>{repeat(i)}}>Repeat</div>
                <div className="flex-1 text-center" onClick={()=>{known(i)}}>I know</div>
            </div></>:
            <><div className="text-white text-3xl p-4">{item.target}</div>
            <div className="text-slate-300 italics text-sm pl-4">{item.type}</div>
            <div className="flex flex-row font-bold mt-2 text-slate p-2 text-white">
                <div className="text-center" onClick={()=>{setShow(true)}}><IoEye className="text-white w-10 p-2 h-10 m-2 bg-sky-700 rounded-full"></IoEye></div>
                <div className="text-center" onClick={()=>{known(i)}}><IoCheckmarkDone className="text-white w-10 p-2 h-10 m-2 bg-sky-700 rounded-full"></IoCheckmarkDone></div>
            </div></>}
            
        </div>
        
    </div>
}
import React, {useRef, useState, useEffect} from 'react';
import {useAuth} from '../auth/AuthContext';
import { getFunctions, httpsCallable } from "firebase/functions";
import { IoEyeOff, IoLogOut, IoEye, IoText } from "react-icons/io5";
import Joke from './Joke';
import { ReactComponent as LanguageIcon } from '../svg/LanguageLoL_web.svg';
import { Link, useParams } from "react-router-dom";
import ViewerMeme from './Viewer/ViewerMeme';
import ViewerFact from './Viewer/ViewerFact';
import ViewerQuote from './Viewer/ViewerQuote';
import {db} from '../firebase';
import { query, getDoc, doc } from "firebase/firestore"; 
import exportAsImage from './Viewer/Downloader';

let lastRandom;

export default function InstagramView(){

    const [content, setContent] = useState([]);
    const {currentUser, processedAuth, logout} = useAuth();
    const [state, setState] = useState(0);
    const { id, lang } = useParams();

    const exportRef = useRef();


    const [currentDisplay] = useState(0);

    const loadFeed = async () => {
        let documentSnapshots = null;
        let cRef = null;
        if(lang === "ru-RU"){
            cRef = doc(db, "content", "lang", "en-EN-ru-RU-content",id);
        } else if(lang === "es-ES"){
            cRef = doc(db, "content", "lang", "en-EN-es-ES-content",id);
        }

        let url = new URL(window.location.href);
        let randomUrlParam = url.searchParams.get("random");

        if(randomUrlParam){
            console.log(randomUrlParam);
            const documentSnapshotsQuery = query(cRef);
            documentSnapshots = await getDoc(documentSnapshotsQuery);
            console.log(documentSnapshots.docs);
        }else{
            const documentSnapshotsQuery = query(cRef);
            documentSnapshots = await getDoc(documentSnapshotsQuery);
        }

        console.log(documentSnapshots.data());
        
        setContent([documentSnapshots.data()]);
    }

    useEffect(() => {
        setContent([]);
        if(processedAuth){
            loadFeed();
        }
        
        return ()=>{
            setContent([]);
        }
    }, [processedAuth]);



    function getCard(item, i){
        if(item.t === "joke"){
            return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}>
                <Joke item={item} i={i} state={state}></Joke>
            </div>
        }else if(item.t === "quote"){
            return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}>
                <ViewerQuote item={item} i={i} state={state}></ViewerQuote>
            </div>
        }else if(item.t === "fact"){
            return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}>
                <ViewerFact item={item} i={i} state={state}></ViewerFact>
            </div>
        }else if(item.t === "meme"){
            return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}>
                <ViewerMeme item={item} i={i} state={state}></ViewerMeme>
            </div>
        }else{
            return ""
        }
    }

    function getCardColor(item){
        if(item){
            if(item.t === "vocab"){
                return "blue";
            }else if(item.t === "joke"){
                return "green";
            }else if(item.t === "fact"){
                return "sky";
            }else if(item.t === "meme"){
                return "purple";
            }else if(item.t === "quote"){
                return "orange";
            }else if(item.t === "wordPair"){
                return "rose";
            }else if(item.t === "rankCard"){
                return "emerald";
            }else if(item.t === "selectAudio"){
                return "emerald";
            }else{
                return "purple"
            }
        }else{
            return "gray";
        }
    }

    return <div className="flex flex-col-reverse sm:flex-row justify-center w-full bg-slate-900 w-screen h-screen">
        <div className="mr-20 flex flex-row sm:h-screen justify-center sm:flex-col items-center sm:items-end">
                {!currentUser?<>
                    <Link to={"/signin"}>
                    <div className="flex flex-row w-full m-1 justify-center">
                    <div className="flex justify-center rounded text-gray-400 font-bold pointer-cursor mr-2 sm:mb-2">
                        Sign in
                    </div>
                    </div>
                </Link>
                <Link to={"/signup"}>
                    <div className="flex flex-row w-full m-1 justify-center">
                    <div className="flex justify-center rounded bg-rose-700 text-white rounded font-bold pointer-cursor p-2 sm:mb-8 sm:mr-0 mr-4">
                        Sign up
                    </div>
                    </div>
                </Link>
                </>:""}
            {content.length>0?<div className="text-slate-200">ID: {content[0].background_url.split(".")[4].split("_")[1]}</div>:""}
            {state===1?<div className="text-center cursor-pointer" onClick={()=>{setState(0)}}><IoEyeOff className="text-white  w-8 h-8 m-2 sm:mt-4 rounded-full"></IoEyeOff></div>
            :<div className="text-center cursor-pointer" onClick={()=>{setState(1)}}><IoEye className="text-white w-8 h-8 m-2 sm:mt-4 rounded-full"></IoEye></div>
            }
            <div className="text-center cursor-pointer" onClick={()=>{setState(2)}}><IoText className="text-white  w-8 h-8 m-2 sm:mt-4"></IoText></div>
            <button onClick={() => exportAsImage(exportRef.current, `meme_${content[0].background_url.split(".")[4].split("_")[1]}${state===0?".translation.png":state===2?".words.translation.png":""}`)}>
                Capture Image
            </button>
            {content.length>0?<a href={`${content[0].meme_audio}`} rel="noreferrer" target="_blank" download>
                Meme Audio
                </a>:""}
            

            
          {currentUser?<IoLogOut onClick={()=>{logout()}} className="cursor-pointer w-8 h-8 m-2 sm:mt-4 text-rose-700"></IoLogOut>:""}
            <Link to={`/list?random=${lastRandom}`}>
                <div className="cursor-pointer test-xs text-white">{lastRandom}</div>
            </Link>
         
        </div>
        <div className="flex flex-col flex-1 sm:flex-none items-center" ref={exportRef}>
            
            <div className="flex flex-col w-screen sm:max-w-lg flex-1 overflow-hidden justify-center overflow-scroll">
            
                <div className="p-8 w-screen sm:w-full overflow-hidden">
                    {content.length>0?<div className={`rotate-3 w-full h-full bg-${getCardColor(content[currentDisplay])}-500 rounded`}>{getCard(content[currentDisplay], currentDisplay)}</div>:<div className="flex flex-row justify-center"><span className="loader"></span></div>}
                    
                    <img src="https://firebasestorage.googleapis.com/v0/b/lang-fix-59804.appspot.com/o/util%2FLanguageLoL.png?alt=media&token=40368544-69cd-4a37-8cd0-cff50b80a4f8" className='h-10 absolute -mt-12 ml-2'></img>
                

                </div>
            </div>
        </div>
    </div>
}
import React, {useRef, useState, useEffect} from 'react';
import {IoEye, IoCheckmarkDone,IoEyeOff, IoGitMergeOutline} from "react-icons/io5";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import generateRandomArray, { getLink, shuffle, timeout } from '../util/Util';
import AudioPlayer from './AudioPlayer';

let originDict = {};
let targetDict = {};

export default function WordPair({item, lang, known}){

    const [correct, setCorrect] = useState(false);

    const [solved, setSolved] = useState([]);

    const [originArr, setOriginArr] = useState([]);
    const [targetArr, setTargetArr] = useState([]);

    const [selected, setSelected] = useState("");

    useEffect(()=>{

        originDict = {};
        targetDict = {};

        let tempItem = {
            origin: [...item.origin],
            target: [...item.target],
            hashes: [...item.hashes],
            uuids: [...item.uuids],
        }

        let tempOriginArr = [];
        let tempTargetArr = [];

        console.log("before",tempItem);

        let randArr = generateRandomArray(5,item.origin.length);

        randArr.forEach((rand,i)=>{
            console.log(rand)
            let org = tempItem.origin[rand];
            let tar = tempItem.target[rand];
            tempOriginArr.push(org);
            tempTargetArr.push([tar,tempItem.hashes[rand],tempItem.uuids[rand]]);
            targetDict[org] = tar;
            originDict[tar] = org;
        });

        setOriginArr(shuffle(tempOriginArr));
        setTargetArr(shuffle(tempTargetArr));
    },[item]);

    

    return <div className="flex flex-col bg-rose-800 justify-between min-card-height rounded">
                <div className="text-xl bg-rose-900 text-white p-4 rounded-t">Tap on the word pairs</div>
                <div className="text-center p-4">
                            {originArr.map((item,i) => {
                                return <div onClick={()=>{
                                    console.log(targetDict,selected, targetDict[item], item);
                                    if(targetDict[item] === selected){
                                        setSolved([...solved,item,selected]);
                                        setSelected(item);
                                        console.log(solved.length+2,originArr.length*2);
                                        if(solved.length+2 === (originArr.length*2)){
                                            known();
                                        }
                                    }else{
                                        setSelected("");
                                    }
                                    setSelected(item)
                                }} className={`cursor-pointer inline-block p-2 my-2 mx-1 whitespace-nowrap ${selected===item?"bg-green-600":solved.includes(item)?"bg-green-600":"bg-rose-600"} rounded-full text-white`}>{item}</div>
                            })}
                            {targetArr.map((item,i) => {
                                return <AudioPlayer 
                                            inactive={400} 
                                            color={"violet"}
                                            size={6} 
                                            audio={getLink(lang,item[1],item[2])} 
                                            play={false}>
                                    <div key={"pairs-"+i} onClick={async ()=>{
                                        console.log(selected, originDict[item[0]], item[0]);
                                        if(originDict[item[0]] === selected){
                                            setSolved([...solved,item[0],selected]);
                                            setSelected(item[0]);
                                            console.log(solved.length+2,originArr.length*2);
                                            if(solved.length+2 === (originArr.length*2)){
                                                await timeout(1000); 
                                                known();
                                            }
                                        }else{
                                            setSelected("");
                                        }
                                        
                                        setSelected(item[0])
                                        }} className={`cursor-pointer inline-block p-2 my-2 mx-1 whitespace-nowrap ${selected===item[0]?"bg-green-600":solved.includes(item[0])?"bg-green-600":"bg-rose-600"} rounded-full text-white`}>
                                        {item[0]}
                                    </div>
                                </AudioPlayer>
                            })}
        
                </div>
                <div></div>
            </div>
        
}
export default function generateRandomArray(size, length, start = []){
    let temp = start;
    while(temp.length < size){
        
        let rand = Math.floor(Math.random()*length);
        if(!temp.includes(rand)){
            temp.push(rand);
        }
    }
    return temp;
}

export function getLink(target,hash,token){
    return `https://firebasestorage.googleapis.com/v0/b/lang-fix-59804.appspot.com/o/Audio%2F${target}%2Faudio_${hash}.mp3?alt=media&token=${token}`;
}

export function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

 export function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}
import React, {useRef, useState, useEffect} from 'react';
import {IoEye, IoCheckmarkDone,IoEyeOff, IoGitMergeOutline} from "react-icons/io5";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import generateRandomArray, { getLink, shuffle, timeout } from '../util/Util';
import AudioPlayer from './AudioPlayer';

export default function CorrectWord({item, known, lang}){ 

    const [correct, setCorrect] = useState(false);
    const [random, setRandom] = useState(0);
    const [choices, setChoices] = useState([]);
    const [lItem, setLitem] = useState(item);

    useEffect(()=>{
        let r = Math.floor(Math.random()*item.target.length);
        setRandom(r);

        
        if(item.target.length < 7){
            let temp = [];

            for(let i = 0; i < item.target.length; i++){
                temp.push([item.target[i],item.hashes[i],item.uuids[i]])
            }

            shuffle(temp)

            setChoices(temp);
        }else{
            let randArr = generateRandomArray(7,item.target.length,[r]);
            let temp = [];
            randArr.forEach((randId) => {
                temp.push([item.target[randId],item.hashes[randId],item.uuids[randId]]);
            })
            shuffle(temp);
            setChoices(temp);
        }
    },[]);

    return <div>
        <div className="flex flex-col bg-violet-800 rounded min-card-height justify-between">
        <div className="text-xl bg-violet-900 text-white p-4 rounded-t">Tab the word that means:
                    <div className="text-4xl my-4 underline text-violet-400">
                        <span className="font-bold">{lItem.origin[random]}</span>
                      
                        </div>
                    </div>
                    
                    <div className="p-4 text-center">{choices.map((choice,i) => {
                        return <AudioPlayer 
                            inactive={400} 
                            color={"violet"} 
                            size={6} 
                            audio={getLink(lang,choice[1],choice[2])} 
                            play={false}>
                                <div onClick={async ()=>{if(choice[0]===lItem.target[random]){setCorrect(true);await timeout(1000);known()}else{console.log("false",choice,i,random,lItem.target[random])}}} 
                                    className={`cursor-pointer inline-block p-2 my-2 mx-1 whitespace-nowrap ${correct&&choice[0]===lItem.target[random]?"bg-green-600":"bg-violet-700"} rounded-full text-white`}>
                                        {choice[0]}
                                </div>
                            </AudioPlayer>
                    })}</div>
        </div>
    </div>
}
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA-eacLBNVuxgn_kiQ9O0ieBB7kqnMWrp8",
  authDomain: "lang-fix-59804.firebaseapp.com",
  projectId: "lang-fix-59804",
  storageBucket: "lang-fix-59804.appspot.com",
  messagingSenderId: "123923279144",
  appId: "1:123923279144:web:dec8784c7ef37f115a341d"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db =  getFirestore(app);
export const auth = getAuth();
import React, {useRef, useState, useEffect} from 'react';
import {useAuth} from '../auth/AuthContext';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useCallback } from 'react';
import { getAuth } from "firebase/auth";
import Vocab from './Vocab';
import { IoEyeOff, IoArrowForward, IoLogOut, IoEye, IoText } from "react-icons/io5";
import Joke from './Joke';
import Fact from './Fact';
import { ReactComponent as LanguageIcon } from '../svg/LanguageLoL_web.svg';
import { Link, useParams } from "react-router-dom";
import RankCard from './RankCard';
import Quote from './Quote';
import ViewerMeme from './Viewer/ViewerMeme';
import ViewerFact from './Viewer/ViewerFact';
import ViewerQuote from './Viewer/ViewerQuote';
import StackGrid from "react-stack-grid";
import {db} from '../firebase';
import { query, orderBy, startAfter, getDocs, collection, limit } from "firebase/firestore";  
import WordPair from './WordPair';
import CorrectWord from './CorrectWord';

let feedBack = [];
let lastRandom;

export default function Showcase(){

    const [content, setContent] = useState([]);
    const {currentUser, processedAuth, logout, currentUserState} = useAuth();
    const [state, setState] = useState(0);

    const functions = getFunctions();
    const getFeed = httpsCallable(functions, 'randomViewer');
    const [lang, setLang] = useState(localStorage.getItem("LANGUAGE_STORAGE_ID")?localStorage.getItem("LANGUAGE_STORAGE_ID"):"ru-RU");

    const [currentDisplay, setCurrentDisplay] = useState(0);

    const loadFeed = async () => {
        let feed = [];
        let documentSnapshots = null;
        let cRef = null;
        if(lang === "ru-RU"){
            cRef = collection(db, "content", "lang", "en-EN-ru-RU-content");
        } else if(lang === "es-ES"){
            cRef = collection(db, "content", "lang", "en-EN-es-ES-content");
        }

        let url = new URL(window.location.href);
        let randomUrlParam = url.searchParams.get("random");

        if(randomUrlParam){
            console.log(randomUrlParam);
            const documentSnapshotsQuery = query(cRef, orderBy("random"), startAfter(Number(randomUrlParam)), limit(20));
            documentSnapshots = await getDocs(documentSnapshotsQuery);
        
        }else{
            const documentSnapshotsQuery = query(cRef,orderBy("random"), limit(20));
            documentSnapshots = await getDocs(documentSnapshotsQuery);
        }

        documentSnapshots.forEach((documentSnapshot) => {
            let temp = documentSnapshot.data();
            temp.id = documentSnapshot.id;
            feed.push(temp);
        });
        lastRandom = documentSnapshots.docs[documentSnapshots.docs.length-1].data().random;
        console.log("last", lastRandom);
        
        console.log(feed);
        setContent([...feed]);
    }

    useEffect(() => {
        setContent([]);
        if(processedAuth){
            loadFeed();
        }
        
        return ()=>{
            setContent([]);
        }
    }, [processedAuth]);

    function setNext(){
        loadFeed();
    }

    function handleKnown(item,i){

        let display = currentDisplay + 1;
        setCurrentDisplay(display);
        if(display === content.length-4){
            loadFeed();
        }
    }

    function handleRepeat(item,i){

        let display = currentDisplay + 1;
        setCurrentDisplay(display);
        if(display === content.length-4){
            loadFeed();
        }
    }


    function getCard(item, i){
        console.log(item.t)
        if(item.t === "joke"){
            return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}>
                <Joke item={item} i={i} state={state}></Joke>
            </div>
        }else if(item.t === "quote"){
            return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}>
                <ViewerQuote item={item} i={i} state={state}></ViewerQuote>
            </div>
        }else if(item.t === "fact"){
            return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}>
                <ViewerFact item={item} i={i} state={state}></ViewerFact>
            </div>
        }else if(item.t === "meme"){
            return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}>
                <ViewerMeme item={item} i={i} state={state}></ViewerMeme>
            </div>
        }else if(item.t === "wordPair"){
            return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}>
                <WordPair item={item} i={i} known={()=>{handleKnown(item,i)}} autoplay={true} repeat={()=>{handleRepeat(item,i)}}></WordPair>
            </div>
        }else if(item.t === "correctWord"){
            return <div className="-rotate-3 shadow-xl" key={item.id+"-"+i}>
            <CorrectWord item={item} i={i} known={()=>{handleKnown(item,i)}} autoplay={true} repeat={()=>{handleRepeat(item,i)}}></CorrectWord>
            </div>
        }else{
            return "hello"
        }
    }

    function getCardColor(item){
        if(item){
            if(item.t === "vocab"){
                return "blue";
            }else if(item.t === "joke"){
                return "green";
            }else if(item.t === "fact"){
                return "sky";
            }else if(item.t === "meme"){
                return "purple";
            }else if(item.t === "quote"){
                return "orange";
            }else if(item.t === "wordPair"){
                return "rose";
            }else if(item.t === "rankCard"){
                return "emerald";
            }else if(item.t === "selectAudio"){
                return "emerald";
            }else{
                return "purple"
            }
        }else{
            return "gray";
        }
    }

    function randomVariation(item){
        let copy = JSON.parse(JSON.stringify(item));
        copy.t = "wordPair";
        return copy;
    }

    return <div className="flex flex-col justify-center items-center w-full bg-slate-900 ">
        <div className="flex flex-row justify-center items-center">
                {!currentUser?<>
                    <Link to={"/signin"}>
                    <div className="flex flex-row w-full m-1 justify-center">
                    <div className="flex justify-center rounded text-gray-400 font-bold pointer-cursor mr-2 ">
                        Sign in
                    </div>
                    </div>
                </Link>
                <Link to={"/signup"}>
                    <div className="flex flex-row w-full m-1 justify-center">
                    <div className="flex justify-center rounded bg-rose-700 text-white rounded font-bold pointer-cursor p-2  mr-4">
                        Sign up
                    </div>
                    </div>
                </Link>
                </>:""}
           
            {state===1?<div className="text-center cursor-pointer" onClick={()=>{setState(0)}}><IoEyeOff className="text-white  w-8 h-8 m-2 sm:mt-4 rounded-full"></IoEyeOff></div>
            :<div className="text-center cursor-pointer" onClick={()=>{setState(1)}}><IoEye className="text-white w-8 h-8 m-2 sm:mt-4 rounded-full"></IoEye></div>
            }
            <div className="text-center cursor-pointer" onClick={()=>{setState(2)}}><IoText className="text-white  w-8 h-8 m-2 sm:mt-4"></IoText></div>
            
          {currentUser?<IoLogOut onClick={()=>{logout()}} className="cursor-pointer w-8 h-8 m-2 sm:mt-4 text-rose-700"></IoLogOut>:""}
          <Link to={`/list?random=${lastRandom}`}>
                <div className="cursor-pointer test-xs text-white">{lastRandom}</div>
            </Link>
        </div>
        <div className="max-w-5xl">
 
            <div className="grid sm:grid-cols-3 gap-8 m-12">
                    <div className="w-full flex items-center justify-center">
                        <img src="https://firebasestorage.googleapis.com/v0/b/lang-fix-59804.appspot.com/o/util%2FLanguageLoL.png?alt=media&token=40368544-69cd-4a37-8cd0-cff50b80a4f8" className='h-14  my-12'></img>
                </div>
                    

                    {content.length>0? content.map((item,i)=>{
                        return <><div><Link to={`/viewer/${lang}/${item.id}`}><div className={`rotate-3 hover:bg-red-500 cursor-pointer bg-${getCardColor(item)}-500 rounded`}>{getCard(item, i)}</div></Link></div>
                             
                        </>
    
                    }) :<div className=""><span className="loader"></span></div>}
                    
                <IoArrowForward onClick={()=>{setNext()}} className={`cursor-pointer m-2 bg-rose-700 m-0 text-white h-12 w-12 p-2 sm rounded-full`}></IoArrowForward>
            </div>
        </div>
    </div>
}
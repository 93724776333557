import React, {useRef, useState, useEffect} from 'react';
import {IoEye, IoCheckmarkDone, IoEyeOff} from "react-icons/io5";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export default function ViewerFact({item, i, state}){

    function textSizes(text){
        if(text.length > 120){
            return "1xl";
        }else{
            return "2xl";
        }
    }

    return <div>
        <div className="flex flex-col bg-sky-800 rounded bg-cover background-tint min-card-height justify-between" style={{backgroundImage: `url(${item.background_url})`}}>
            {state===0?<div className={`text-white text-${textSizes(item.back)} p-8 tracking-wide leading-relaxed`}>{item.back}</div>:
            state===1?<div className={`text-white text-${textSizes(item.text)} p-8 tracking-wide leading-relaxed`}>{item.text}</div>:
            <div className={`flex flex-col p-8 text-white text-${textSizes(item.text)} `}>
                <div className="font-serif font-bold">Words:</div>
                <div className={`flex flex-col flex-1`}>
                    {item.target.slice(0,16).map((word,i)=>{
                        if(word.length > 3){
                            return <div>{word} - <span className="text-orange-200">{item.origin[i]}</span></div>
                        }else{
                            return "";
                        }
                    })}
                    </div>
                    <div className={`flex flex-col flex-1`}>
                    {item.target.slice(16,item.target.length-1).map((word,i)=>{
                        if(word.length > 3){
                            return <div>{word} - <span className="text-orange-200">{item.origin[16+i]}</span></div>
                        }else{
                            return "";
                        }
                    })}
                    </div></div>}
        </div>
    </div>
}
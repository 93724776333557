import React, {useRef, useState, useEffect} from 'react';
import {IoEye, IoCheckmarkDone, IoEyeOff} from "react-icons/io5";
import { getLink } from '../util/Util';
import AudioPlayer from './AudioPlayer';

export default function Joke({item, i, repeat, known, autoplay}){

    const [show,setShow] = useState(false);

    const [showed, setShowed] = useState(false);

    return <div>
        <div className="flex flex-col bg-green-600 rounded">
            {show?<div className="text-white text-3xl p-4">{item.back}</div>
          :
            <div className="text-white text-3xl p-8">{item.text}</div>
            }

            <div className="flex flex-row font-bold mt-2 text-white p-2 text-white">
            {show?
                <> <div className="text-center cursor-pointer" onClick={()=>{setShow(false)}}><IoEyeOff className="text-white w-10 p-2 h-10 m-2 bg-green-900 rounded-full"></IoEyeOff></div>
                    <div className="text-center cursor-pointer" onClick={()=>{known(i)}}><IoCheckmarkDone className="text-white w-10 p-2 h-10 m-2 bg-green-900 rounded-full"></IoCheckmarkDone></div></>
            :<><div className="text-center cursor-pointer" onClick={()=>{setShow(true); setShowed(true)}}><IoEye className="text-white w-10 p-2 h-10 m-2 bg-green-900 rounded-full"></IoEye></div>
            <div className="text-center cursor-pointer" onClick={()=>{showed?repeat(i):known(i)}}><IoCheckmarkDone className="text-white w-10 p-2 h-10 m-2 bg-green-900 rounded-full"></IoCheckmarkDone></div></>
            }
            {item.uuid?<div className=" w-10 p-2 h-10 m-2 bg-green-900 rounded-full"><AudioPlayer inactive={400} color={"green"} audio={getLink("ru-RU",item.hash,item.uuid)} size={6} play={autoplay}></AudioPlayer></div>:""}
            </div>
        </div>
    </div>
}
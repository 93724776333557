import React, {useRef, useState, useEffect} from 'react';
import {IoEye, IoCheckmarkDone, IoEyeOff} from "react-icons/io5";
import { GiRank3 } from "react-icons/gi";

export default function RankCard({next}){

    return <div>
        <div className="flex flex-col bg-green-600 rounded">
            <div className="text-6xl flex flex-row font-bold mt-2 text-white p-2 text-white">
                <div className="text-white font-bold glow-shadow-green flex flex-row items-center mr-2"><GiRank3 className='glow-shadow-green-drop mr-1'></GiRank3> Private</div>
            </div>
            <div className="flex flex-row font-bold mt-2 text-white p-2 text-white">
                <div className="text-center" onClick={()=>{next()}}><IoCheckmarkDone className="text-white w-10 p-2 h-10 m-2 bg-green-700 rounded-full"></IoCheckmarkDone></div>
            </div>
        </div>
    </div>
}
import { Dialog } from "@headlessui/react";
import React, { useRef, useState, useEffect } from "react";
import {
  IoExtensionPuzzle,
  IoCloseOutline,
  IoSearchOutline,
  IoSettings,
} from "react-icons/io5";

import { getAuth, deleteUser, reauthenticateWithCredential, EmailAuthProvider, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { where, getDocs,onSnapshot,doc,addDoc, query, collection } from "firebase/firestore";

import { ReactComponent as RussianFlag } from "../svg/flag_ru.svg";
import { ReactComponent as SpanishFlag } from "../svg/flag_es.svg";
import { useAuth } from "../auth/AuthContext";

import { auth } from '../firebase';

import { FcGoogle } from 'react-icons/fc'

export function SettingsDialog({ isOpen, setIsOpen, setLang }) {
  const [value, setValue] = useState(
    localStorage.getItem("LANGUAGE_STORAGE_ID")
      ? localStorage.getItem("LANGUAGE_STORAGE_ID")
      : "ru-RU"
  );
  const [displayDelete, setDisplayDelete] = useState(false);

  const passwordRef = useRef();
  const [error, setError] = useState("");
  const { clearLocalStorage } = useAuth();



  async function delGoogle() {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        // The signed-in user info.
        const user = result.user;

        reauthenticateWithCredential(user, credential)
          .then(() => {
            deleteUser(user)
              .then(() => {
                clearLocalStorage();
                window.location.reload(false);
              })
              .catch((error) => {
                console.log(error);
                alert("an error occured, try it later again");
              });
          })
          .catch((error) => {
            console.log(error);
            alert("an error occured, try it later again");
          });
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  }

  function del() {
    const auth = getAuth();
    const user = auth.currentUser;
    console.log(user.email);

    if (!passwordRef.current.value) {
      return setError("Password not provided");
    }

    const credential = EmailAuthProvider.credential(
      user.email,
      passwordRef.current.value
    );
    reauthenticateWithCredential(user, credential)
      .then(() => {
        deleteUser(user)
          .then(() => {
            clearLocalStorage();
            window.location.reload(false);
          })
          .catch((error) => {
            console.log(error);
            alert("an error occured, try it later again");
          });
      })
      .catch((error) => {
        console.log(error);
        alert("an error occured, try it later again");
      });
  }

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <div
        className="fixed inset-0 flex items-center  justify-center p-4 sm:p-8 inset-0 bg-black/30 z-50"
        aria-hidden="true"
      >
        <Dialog.Panel className="flex flex-col h-full w-full sm:ml-20 sm:max-w-md bg-slate-800 rounded-xl z-50 overflow-scroll pb-8">
          <Dialog.Title className="sticky top-0  flex flex-row text-xl rounded-t-xl p-4 justify-between content-center font-bold bg-rose-700 text-slate-300">
            <div className="flex flex-row content-center items-center">
              <IoSettings className=" mr-2 h-6 w-6" />
              <div>Settings</div>
            </div>
            <IoCloseOutline
              onClick={() => {
                setIsOpen(false);
              }}
              className="cursor-pointer h-8 w-8"
            />
          </Dialog.Title>

          <div className="pl-8 pt-8 pb-4 text-xl text-slate-300">Language</div>
          <div className="flex flex-row pl-8 cursor-pointer">
          <SpanishFlag
                className={`${
                    value === "es-ES"
                    ? "p-1 border-4 border-gray-500 dark:border-gray-200"
                    : "p-2"
                } w-16 h-16 rounded-full`}
                onClick={() => {
                    setValue("es-ES");
                    setLang("es-ES");
                    localStorage.setItem("LANGUAGE_STORAGE_ID", 'es-ES');
                }}
                ></SpanishFlag>
            <RussianFlag
              className={`${
                value === "ru-RU"
                  ? "p-1 border-4 border-gray-500 dark:border-gray-200"
                  : "p-2"
              } w-16 h-16 rounded-full`}
              onClick={() => {
                setValue("ru-RU");
                setLang("ru-RU");
                localStorage.setItem("LANGUAGE_STORAGE_ID", "ru-RU");
              }}
            ></RussianFlag>
          </div>

          <div className="flex flex-col justify-between my-2 text-white pl-8 py-8 bg-slate-900 mt-4">
            <div className="pb-4 text-xl text-slate-300">Account</div>
            
            {displayDelete ? (
              <div className="mr-8">
             

                <div className="mt-6">
                  <label htmlFor="password" className="mt-3 ">
                    Authenticate to delete
                  </label>
                  <input
                    className="appearance-none rounded-full relative block w-full border border-slate-300
                placeholder-slate-500 mt-4 rounded-full focus:outline-none dark:bg-slate-700

                focus:ring-rose-700 focus:border-rose-700 pr-3 py-2"
                    id="password"
                    name="password"
                    type="password"
                    placeholder="********"
                    ref={passwordRef}
                  />
                </div>
                <p className="mb-4 text-sm font-bold text-rose-600"> {error}</p>
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row">
                    <div
                      onClick={delGoogle}
                      className="cursor-pointer shadow inline flex flex-row justify-center items-center rounded-full bg-rose-700 px-4 h-12 mr-3"
                    >
                      <FcGoogle className="h-6 w-6" />
                    </div>
                    <button
                      className="rounded-full p-2 bg-rose-700 shadow text-white font-bold h-12"
                      onClick={() => del()}
                    >
                      Delete
                    </button>
                  </div>

                  <button
                    className="rounded-full p-2 bg-white dark:bg-slate-700 shadow h-12"
                    onClick={() => setDisplayDelete(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div>
              <div
                onClick={() => setDisplayDelete(true)}
                className="inline cursor-pointer rounded-full p-2 bg-rose-700 text-white"
              >
                Delete account
              </div>
            </div>
            )}
          </div>

          <div className="flex flex-col justify-between pt-8 pl-8">
            <p className="font-bold text-slate-400">
              Company Details
            </p>
            <div className="container text-slate-100 mb-4">
              <p>nova UG (haftungsbeschränkt)</p>
              <p>Ziegelgrund 25</p>
              <p>D-87755 Kirchhaslach</p>
              <p className="font-bold mt-4 text-slate-400">Contact</p>
              <p>Joshua Schmidt</p>
              <p>E-Mail: info@lang.blue</p>
            </div>
            <a
              href="https://www.iubenda.com/privacy-policy/46179223"
              rel="noreferrer"
              target="_blank"
              className="font-bold text-rose-700 outline-none"
              title="Privacy Policy "
            >
              Privacy Policy
            </a>
            <a
              href="https://www.iubenda.com/terms-and-conditions/46179223"
              rel="noreferrer"
              target="_blank"
              className="font-bold text-rose-700"
              title="Privacy Policy "
            >
              Terms and Conditions
            </a>
            <a
              href="https://www.iubenda.com/privacy-policy/46179223/cookie-policy"
              rel="noreferrer"
              target="_blank"
              className="font-bold text-rose-700"
              title="Privacy Policy "
            >
              Cookie Policy
            </a>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

/* eslint-disable linebreak-style */
/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React, {useRef, useState, useEffect} from 'react';
import {useAuth} from './AuthContext';
import {useNavigate } from 'react-router-dom';
import {FcGoogle} from 'react-icons/fc';
import { StateButton, STATES, } from '../util/StateButton';

/**
 * component with all signup functionality
 * @return {object} jsx sign in component
 */
export default function SignIn() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const {signIn, signInWithGoogle, currentUser} = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [googleLoginState, setGoogleLoginState] = useState(STATES.STATE_NORMAL);
  const [loginState, setLoginState] = useState(STATES.STATE_NORMAL);

  useEffect(() => {
    console.log("curent user", currentUser);
    if(currentUser){
      
      navigate("/feed");
    }
  },[currentUser]);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!passwordRef.current.value) {
      return setError('Password not provided');
    }

    if (!emailRef.current.value) {
      return setError('email not provided');
    }

    setLoginState(STATES.STATE_PROCESS);
    setError('');
    setLoading(true);
    let success = await signIn(emailRef.current.value, passwordRef.current.value);
    console.log("success", success);
    if(success){
      setTimeout(function(){  navigate('/feed'); }, 1000);
    }else{
      console.log("error");
      setError('No signin possible');
      setLoginState(STATES.STATE_ERROR);
    }

    setLoading(false);
  }

  async function googleLogin(e){
    console.log("start google login");
    try {
        setGoogleLoginState(STATES.STATE_PROCESS);
        setError('');
        setLoading(true);
        let login = await signInWithGoogle();
        console.log("got google login response",login);
        window.location.href = "/feed";
      } catch(e) {
        console.log(e);
        setError('No sign in possible');
        setGoogleLoginState(STATES.STATE_ERROR);
      }
  }

  return (
    <div className="min-h-screen flex items-center justify-center dark:bg-slate-900 bg-slate-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <h2 className="text-center text-3xl font-extrabold text-rose-700">Sign in</h2>
        <div className="">
          <form onSubmit={handleSubmit}>
            <p className="text-center text-sm font-bold text-red-800"> {error}
            </p>
            <div>
              <label htmlFor="email-address" className='text-slate-700 dark:text-slate-200'>Email address</label>
              <input
                className="mt-2 appearance-none rounded-full relative block w-full border border-slate-300
                        placeholder-slate-500 text-slate-500 rounded-full focus:outline-none
                        focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2"
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="email@mail.com"
                ref={emailRef}/>
            </div>
            <div className="mt-2">
              <label htmlFor="password" className="mt-3 text-slate-700 dark:text-slate-200">Password</label>
              <input
                className="mt-2 appearance-none rounded-full relative block w-full border border-slate-300
                placeholder-slate-500 text-slate-500 rounded-full focus:outline-none
                focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2"
                id="password"
                name="password"
                type="password"
                placeholder="********"
                ref={passwordRef}/>
            </div>
            <div className="flex flex-row justify-between">
              <div onClick={googleLogin} className="cursor-pointer mt-6 shadow flex flex-row justify-center items-center rounded-full bg-white text-center text-slate py-3 px-3 mr-3"><StateButton state={googleLoginState}><FcGoogle className="h-6 w-6"/></StateButton></div>
              <button disabled={loading} className="flex flex-row justify-center items-center mt-6 rounded-full block w-full bg-rose-700 text-center text-white py-3"><StateButton state={loginState}></StateButton>Sign In</button>
            </div>
          </form>
        </div>
        <div className="flex justify-between">
          <a href="/signUp" className="font-medium text-rose-700 hover:text-rose-300">Sign up</a>
          <a href="/forgot" className="font-medium text-rose-700 hover:text-rose-300">forgot password</a>
        </div>
      </div>
    </div>
  );
}

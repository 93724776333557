import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { AuthProvider } from './auth/AuthContext';
import PrivateRouter, { AdminRoute } from './auth/PrivateRouter';
import SignUp from './auth/SignUp';
import SignIn from './auth/SignIn';
import Feed from './Feed/Feed';
import Landing from './Landing/Landing';
import InstagramView from './Feed/InstagramMode';
import ViewerList from './Feed/ViewerList';
import Showcase from './Feed/Showcase';

export default function App() {
    return (
        <div className="text-slate-700">
            <AuthProvider>
                <Router>
                    <Routes>
                        <Route path="/signup" element={<SignUp/>}/>
                        <Route path="/signin" element={<SignIn/>}/>
                        <Route path="/feed" element={<Feed/>}/>
                        <Route path="/" element={<Feed/>}/>
                        <Route path="/viewer/:lang/:id" element={<InstagramView/>}/>
                        <Route path="/list" element={<ViewerList/>}/>
                        <Route path="/showcase" element={<Showcase/>}/>
                    </Routes>
                </Router>
            </AuthProvider>
        </div>
    )
  }
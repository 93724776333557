import React, {useRef, useState, useEffect} from 'react';
import {IoEye, IoCheckmarkDone,IoEyeOff, IoPlay} from "react-icons/io5";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import AudioPlayer from './AudioPlayer';

export default function Meme({item, i, repeat, known, autoplay}){

    const [show,setShow] = useState(false);

    const [memeAudio,setMemeAudio] = useState(item.meme_audio);

    const [showed, setShowed] = useState(false);

    return <div>
        <div className="bg-purple-800 rounded">
            <div className="meme_max_height overflow-scroll">
            {item.translation_url&&show?<img src={item.translation_url} className="rounded-t"></img>:<img src={item.background_url} className="rounded-t"></img>}
            </div>
        
        
        {show&&item.back?<div className=" text-purple-900 bg-white-tr bg-contain bg-no-repeat p-4 bg-center">
            
            <div className="">
                <ReactMarkdown children={item.back} remarkPlugins={[remarkGfm]} components={{
                p: ({node, ...props}) => <p className='' {...props} />,
                ul: ({node, ...props}) => <ul className='list-disc list-outside ml-4' {...props} />,
                ol: ({node, ...props}) => <ol className='list-decimal list-outside ml-4' {...props} />,
                li: ({node, ...props}) => <li className='mb-2' {...props} />,
                hr: ({node, ...props}) => <div className='w-32 h-2 border-b-4 border-dotted border-slate-200 dark:border-slate-700 my-6' {...props} />,
                em: ({node, ...props}) => <p className='pl-4 border-l-4 border-purple-900 my-2' {...props} />,
                table: ({node, ...props}) => <table cellPadding={8} className=""{...props} />,
                td: ({node,children, ...props}) => (<td className='m-2 dark:border-slate-800' {...props}>{children}</td>),
                h2: ({node,children, ...props}) => (<h2 className='text-2xl mb-4' {...props}>{children}</h2>),
                h1: ({node,children, ...props}) => (<h1 className='text-3xl mb-4' {...props}>{children}</h1>)
              }}/>
            </div>
            
        </div>:""}
            

            <div className="flex flex-row font-bold mt-2 text-white p-2 text-white">
            {show?
                <> <div className="text-center cursor-pointer" onClick={()=>{setShow(false)}}><IoEyeOff className="text-white w-10 p-2 h-10 m-2 bg-purple-900 rounded-full"></IoEyeOff></div>
                    <div className="text-center cursor-pointer" onClick={()=>{known(i)}}><IoCheckmarkDone className="text-white w-10 p-2 h-10 m-2 bg-purple-900 rounded-full"></IoCheckmarkDone></div></>
            :<><div className="text-center cursor-pointer" onClick={()=>{setShow(true); setShowed(true)}}><IoEye className="text-white w-10 p-2 h-10 m-2 bg-purple-900 rounded-full"></IoEye></div>
            <div className="text-center cursor-pointer" onClick={()=>{showed?repeat(i):known(i)}}><IoCheckmarkDone className="text-white w-10 p-2 h-10 m-2 bg-purple-900 rounded-full"></IoCheckmarkDone></div></>
            }
            {item.meme_audio?<div className=" w-10 p-2 h-10 m-2 bg-purple-900 rounded-full"><AudioPlayer inactive={400} color={"violet"} audio={item.meme_audio} size={6} play={autoplay}></AudioPlayer></div>:""}
            
            </div>

            
            
        </div>
        
    </div>
}
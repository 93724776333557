import React, { useState, useEffect } from 'react';
import { IoVolumeHigh } from "react-icons/io5";


/**
 * returns component for documents
 * @return {object} jsx documents component
 */

const useAudio = a => {
  const [audio] = useState(a);
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
      playing ? audio.play() : audio.pause();
    },
    [playing]
  );

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

export default function AudioPlayer({audio, play, size=10,color="slate", active=500, inactive=300, children}) {
  const [playing, toggle] = useAudio(new Audio(audio));
  useEffect(() => {
      if(play){
        toggle();
      }
  }, []);

  return (
    (children?<div className={`cursor-pointer inline-flex flex-row items-center ${playing ? "pl" : ""}`} onClick={toggle}>

      {children}
      </div>:
    
    <IoVolumeHigh onClick={toggle} className={`cursor-pointer h-${size} w-${size} text-${color}-${playing ? active : inactive}`}></IoVolumeHigh>)
  );
}

import React, {useRef, useState, useEffect} from 'react';
import {IoFlame} from "react-icons/io5";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export default function StreakCounter({day}){
    return <div className="text-amber-500 font-bold glow-shadow-orange flex flex-row items-center mr-2">
            <IoFlame className='glow-shadow-orange-drop mr-1'></IoFlame>
            <div>{day}</div>
        </div>
}
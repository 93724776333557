import React, {useRef, useState, useEffect} from 'react';
import {IoEye, IoCheckmarkDone, IoEyeOff} from "react-icons/io5";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export default function ViewerQuote({item, i, state}){

    function textSizes(text){
        if(text.length > 100){
            return "1xl";
        }else{
            return "2xl";
        }
    }

    return <div>
        <div className="flex flex-col bg-orange-800 min-card-height justify-between rounded bg-cover background-orange pb-4" style={{backgroundImage: `url(${item.background_url})`}}>
            {state===0?<div className={`text-white px-8 pt-8 tracking-wide text-${textSizes(item.back)} leading-relaxed font-serif`}>{item.back}<span className='block mt-4 ml-4 text-orange-300 font-thin italic'>{item.back_author}</span></div>:
            state===1?<div className={`text-white px-8 pt-8 tracking-wide text-${textSizes(item.text)} leading-relaxed font-serif`}>{item.text}<span className='block mt-4 ml-4 text-orange-300 font-thin italic'>{item.text_author}</span></div>:
            <div className={`flex flex-col p-8 text-white text-${textSizes(item.text)} `}>
                <div className="font-serif font-bold">Words:</div>
                <div className={`flex flex-col flex-1`}>
                    {item.target.slice(0,16).map((word,i)=>{
                        if(word.length > 3){
                            return <div>{word} - <span className="text-orange-200">{item.origin[i]}</span></div>
                        }else{
                            return "";
                        }
                    })}
                    </div>
                    <div className={`flex flex-col flex-1`}>
                    {item.target.slice(16,item.target.length-1).map((word,i)=>{
                        if(word.length > 3){
                            return <div>{word} - <span className="text-orange-200">{item.origin[16+i]}</span></div>
                        }else{
                            return "";
                        }
                    })}
                    </div></div>}            
        </div>
    </div>
}